import { DEFAULT_LANG, RenewalTimeUnit } from "common/lib/constants";
import { WidgetConfig } from "common/lib/interfaces";

export const MAX_Z_INDEX = 2147483644; // 2 ** 31 - 1 - 3(number of floors);
export const MIN_Z_INDEX = -MAX_Z_INDEX;
export const DEFAULT_Z_INDEX = 99999999;
export const WIDGET_FASTLANE_Z_INDEX = 1030;

export const validateZIndex = (zIndex?: number, fastlane?: boolean): number => {
    if (fastlane) {
        return WIDGET_FASTLANE_Z_INDEX;
    }

    if (typeof zIndex !== "number") {
        return DEFAULT_Z_INDEX;
    }

    return Math.min(MAX_Z_INDEX, Math.max(MIN_Z_INDEX, zIndex));
};

export const setZIndex = (zIndex: number): void => {
    const css = document.createElement("style");
    css.innerHTML = `:root {
    --usc-outlet-z-index: ${zIndex};
    --usc-outlet-z-index-floor0: ${zIndex - 1};
    --usc-outlet-z-index-floor1: ${zIndex + 1};
    --usc-outlet-z-index-floor2: ${zIndex + 2};
    }`;
    document.body.appendChild(css);
};

export const validateConfig = (config: WidgetConfig): WidgetConfig => {
    if (!config?.lang?.startsWith("en") && !config?.lang?.startsWith("es")) {
        return { ...config, lang: DEFAULT_LANG };
    }

    return config;
};

export function toRenewalTimeUnit(renewalTimeUnit: string) {
    return Object.values<string>(RenewalTimeUnit).includes(renewalTimeUnit)
        ? (renewalTimeUnit as RenewalTimeUnit)
        : RenewalTimeUnit.Days;
}
