export var UscStorageKeys;
(function (UscStorageKeys) {
    UscStorageKeys["CONTENT_DATA_STORAGE"] = "contentData";
    UscStorageKeys["CONTENT_DATA_BY_PRODUCT_KEYS"] = "contentDataByProductKeys";
    UscStorageKeys["CCW_PRODUCTS_STORAGE"] = "contextualProductsStorage";
    UscStorageKeys["RESPONSIVE_ADS_PRODUCT_KEYS_STORAGE"] = "productKeys";
    UscStorageKeys["SPONSORSHIP_STORAGE"] = "sponsorshipStorage";
    UscStorageKeys["SPONSORED_PRODUCTS_STORAGE"] = "sponsoredProductsStorage";
    UscStorageKeys["REVENUE_ENTITY"] = "revenueEntity";
})(UscStorageKeys || (UscStorageKeys = {}));
