import { cloneDeep } from "lodash-es";
import { ContentType } from "../../constants/analytics";
import { UscStorageKeys } from "../../constants/data";
const DEFAULT_CONTENT_TYPE = "Web Page";
export const uscDataStorageEmpty = {
    [UscStorageKeys.CONTENT_DATA_STORAGE]: {
        contentType: "",
        contentName: ""
    },
    [UscStorageKeys.CONTENT_DATA_BY_PRODUCT_KEYS]: {},
    [UscStorageKeys.CCW_PRODUCTS_STORAGE]: [],
    [UscStorageKeys.RESPONSIVE_ADS_PRODUCT_KEYS_STORAGE]: [],
    [UscStorageKeys.SPONSORSHIP_STORAGE]: {},
    [UscStorageKeys.SPONSORED_PRODUCTS_STORAGE]: []
};
export const getUscDataStorage = () => {
    return window.uscWidget.uscDataStorage;
};
export const setUscDataStorage = (data) => {
    var _a;
    if (!((_a = window === null || window === void 0 ? void 0 : window.uscWidget) === null || _a === void 0 ? void 0 : _a.uscDataStorage)) {
        return;
    }
    window.uscWidget.uscDataStorage = Object.assign(Object.assign({}, cloneDeep(window.uscWidget.uscDataStorage)), data);
};
export const getDefaultContentData = () => {
    var _a, _b;
    if ((_b = (_a = window.uscWidget) === null || _a === void 0 ? void 0 : _a.config) === null || _b === void 0 ? void 0 : _b.getDefaultContentData) {
        const { contentName, contentType, sponsorName } = window.uscWidget.config.getDefaultContentData();
        return Object.assign({ contentName,
            contentType }, (sponsorName && {
            sponsorName
        }));
    }
    return {
        contentName: document.title,
        contentType: DEFAULT_CONTENT_TYPE
    };
};
export const readContentDataFromStorage = (productKey) => {
    var _a, _b;
    const initValue = getDefaultContentData();
    const uscData = getUscDataStorage();
    if (productKey &&
        ((_a = uscData[UscStorageKeys.CONTENT_DATA_BY_PRODUCT_KEYS]) === null || _a === void 0 ? void 0 : _a[productKey])) {
        return uscData[UscStorageKeys.CONTENT_DATA_BY_PRODUCT_KEYS][productKey];
    }
    if ((_b = uscData[UscStorageKeys.CONTENT_DATA_STORAGE]) === null || _b === void 0 ? void 0 : _b.contentType) {
        return uscData[UscStorageKeys.CONTENT_DATA_STORAGE];
    }
    return initValue;
};
export const writeContentDataByTypeToStorage = (content) => {
    const initValue = getDefaultContentData();
    const uscWidgetData = getUscDataStorage();
    switch (content === null || content === void 0 ? void 0 : content.type) {
        case ContentType.CCW:
            uscWidgetData[UscStorageKeys.CCW_PRODUCTS_STORAGE] =
                content.products || [];
            break;
        case ContentType.SPONSORSHIP_BANNER:
            uscWidgetData[UscStorageKeys.SPONSORSHIP_STORAGE] = {
                pageSegmentName: content.pageSegmentName || null
            };
            break;
        case ContentType.SPONSORED_PRODUCTS:
            uscWidgetData[UscStorageKeys.SPONSORED_PRODUCTS_STORAGE] =
                content.products.split(",");
            break;
        case ContentType.AD: {
            const productKeys = content.products.split(",");
            uscWidgetData[UscStorageKeys.SPONSORED_PRODUCTS_STORAGE] =
                productKeys;
            uscWidgetData[UscStorageKeys.CONTENT_DATA_STORAGE] = Object.assign(Object.assign(Object.assign({}, uscWidgetData[UscStorageKeys.CONTENT_DATA_STORAGE]), { contentType: ContentType.AD }), (content.name ? { contentName: content.name } : {}));
            uscWidgetData[UscStorageKeys.CONTENT_DATA_BY_PRODUCT_KEYS] =
                productKeys.reduce((acc, productKey) => {
                    var _a;
                    return (Object.assign(Object.assign({}, acc), { [productKey]: {
                            contentType: ContentType.AD,
                            contentName: (_a = content.name) !== null && _a !== void 0 ? _a : uscWidgetData.contentData.contentName
                        } }));
                }, {});
            break;
        }
        default:
            uscWidgetData[UscStorageKeys.CONTENT_DATA_STORAGE] = {
                contentType: (content === null || content === void 0 ? void 0 : content.type) || initValue.contentType,
                contentName: (content === null || content === void 0 ? void 0 : content.name) || initValue.contentName
            };
    }
    setUscDataStorage(uscWidgetData);
};
export const writeContentDataToStorage = (content) => {
    const initValue = getDefaultContentData();
    const uscWidgetData = getUscDataStorage();
    uscWidgetData[UscStorageKeys.CONTENT_DATA_STORAGE] = {
        contentType: (content === null || content === void 0 ? void 0 : content.type) || initValue.contentType,
        contentName: (content === null || content === void 0 ? void 0 : content.name) || initValue.contentName
    };
    setUscDataStorage(uscWidgetData);
};
export const readResponsiveAdsProductKeysFromStorage = () => {
    const uscWidgetData = getUscDataStorage();
    return uscWidgetData[UscStorageKeys.RESPONSIVE_ADS_PRODUCT_KEYS_STORAGE];
};
export const writeResponsiveAdsProductKeyToStorage = (productKey) => {
    const uscWidgetData = getUscDataStorage();
    const productKeys = uscWidgetData[UscStorageKeys.RESPONSIVE_ADS_PRODUCT_KEYS_STORAGE] || [];
    uscWidgetData[UscStorageKeys.RESPONSIVE_ADS_PRODUCT_KEYS_STORAGE] =
        Array.from(new Set([...productKeys, productKey]));
    setUscDataStorage(uscWidgetData);
};
export const clearResponsiveAdsProductKeysStorage = () => {
    const uscWidgetData = getUscDataStorage();
    setUscDataStorage(Object.assign(Object.assign({}, uscWidgetData), { [UscStorageKeys.RESPONSIVE_ADS_PRODUCT_KEYS_STORAGE]: [] }));
};
export const readSponsorshipDataFromStorage = () => {
    var _a;
    const initValue = {
        pageSegmentName: null
    };
    const uscWidgetData = getUscDataStorage();
    if ((_a = uscWidgetData[UscStorageKeys.SPONSORSHIP_STORAGE]) === null || _a === void 0 ? void 0 : _a.pageSegmentName) {
        return uscWidgetData[UscStorageKeys.SPONSORSHIP_STORAGE];
    }
    return initValue;
};
export const readCcwContentDataFromStorage = () => {
    const uscWidgetData = getUscDataStorage();
    return uscWidgetData[UscStorageKeys.CCW_PRODUCTS_STORAGE];
};
export const readSponsoredProductsFromStorage = () => {
    const uscWidgetData = getUscDataStorage();
    return uscWidgetData[UscStorageKeys.SPONSORED_PRODUCTS_STORAGE];
};
