import Analytics from "./analytics";
const fromCentAmountToDecimal = (centAmount = 0, fractionDigits = 2) => (centAmount / 100).toFixed(fractionDigits);
export const formatPriceValue = (price, lang = "en-US") => {
    const dollars = parseFloat(fromCentAmountToDecimal(price));
    return dollars.toLocaleString(lang, {
        style: "currency",
        currency: "USD"
    });
};
export var CTImageSize;
(function (CTImageSize) {
    CTImageSize["Small"] = "small";
    CTImageSize["Medium"] = "medium";
    CTImageSize["Large"] = "large";
})(CTImageSize || (CTImageSize = {}));
const imgExtension = ["jpeg", "jpg", "png", "bmp", "gif"];
export const getCTImageUrl = (imgUrl, size) => {
    const indexOfDot = imgUrl.lastIndexOf(".");
    const url = imgUrl.slice(0, indexOfDot);
    const ext = imgUrl.slice(indexOfDot + 1);
    return imgExtension.includes(ext) ? `${url}-${size}.${ext}` : imgUrl;
};
export const getDiscount = (originPrice, price) => Math.ceil(((originPrice - price) * 100) / originPrice);
export const getMerchandiseDataAttributes = (merchandiseData) => JSON.stringify({
    type: merchandiseData === null || merchandiseData === void 0 ? void 0 : merchandiseData.type,
    groupAttributeName: merchandiseData === null || merchandiseData === void 0 ? void 0 : merchandiseData.groupAttributeName,
    groupAttributeValue: merchandiseData === null || merchandiseData === void 0 ? void 0 : merchandiseData.groupAttributeValue,
    sku: merchandiseData === null || merchandiseData === void 0 ? void 0 : merchandiseData.sku
}).replace(/"/g, "'");
export const formatProductData = (products, contentType) => {
    if (!Array.isArray(products) || !products.length)
        return [];
    return products.map((product, key) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const masterProduct = (_a = product.variants) === null || _a === void 0 ? void 0 : _a[0];
        const offer = masterProduct.offers[0];
        const price = (_b = offer === null || offer === void 0 ? void 0 : offer.price) !== null && _b !== void 0 ? _b : 0;
        const originPrice = (_c = offer === null || offer === void 0 ? void 0 : offer.originPrice) !== null && _c !== void 0 ? _c : 0;
        const discount = getDiscount(originPrice, price);
        const merchandiseData = product.merchandiseData
            ? getMerchandiseDataAttributes(product.merchandiseData)
            : undefined;
        const analyticsCustomAttributes = Analytics.getCustomAttributes({
            contentType
        });
        const hasDelineator = !product.isSponsored && !((_d = products[key - 1]) === null || _d === void 0 ? void 0 : _d.isSponsored);
        return {
            id: product.id,
            adId: product.adId,
            name: product.name,
            productKey: product.key,
            sku: (_e = product === null || product === void 0 ? void 0 : product.merchandiseData) === null || _e === void 0 ? void 0 : _e.sku,
            description: product.description,
            brandName: (_f = masterProduct === null || masterProduct === void 0 ? void 0 : masterProduct.sameAttributes) === null || _f === void 0 ? void 0 : _f.brand,
            images: masterProduct === null || masterProduct === void 0 ? void 0 : masterProduct.images.map(image => image.url),
            channelKey: (_g = offer === null || offer === void 0 ? void 0 : offer.channel) === null || _g === void 0 ? void 0 : _g.key,
            isOnStock: offer === null || offer === void 0 ? void 0 : offer.isOnStock,
            isSponsored: product.isSponsored,
            seller: (_h = offer === null || offer === void 0 ? void 0 : offer.channel) === null || _h === void 0 ? void 0 : _h.name,
            merchandiseData,
            analyticsCustomAttributes,
            discount,
            originPrice,
            price,
            hasDelineator
        };
    });
};
