import { Component, h, createRef, RefObject } from "preact";
import classNames from "classnames";

import { Theme } from "common/lib/constants/config";
import {
    SHOPPING_OUTLET,
    SHOPPING_OUTLET_CONTENT,
    CLASS_NAME_FOR_SHOWN_WIDGET_ON_BODY
} from "../../constants/components.registry";
import {
    getShoppableProducts,
    CHANNEL_KEY_ATTRIBUTE,
    PRODUCT_KEY_ATTRIBUTE,
    updateBodyClassList,
    ProductPlacement
} from "../../utils/dom";
import { clearLocationHash } from "../../utils/url";
import { isIPhone } from "../../utils/platform";
import { loadWidget, loadWidgetV3 } from "../../utils/loader";

import "./ShoppingOutlet.scss";

interface ShoppingOutletProps {
    theme: Theme;
    lang: string;
    onCartAmountChange: (cartAmount: number) => void;
    iframeProducts: ProductPlacement[];
    hideShoppingButton: (isHide: boolean) => void;
    uscRevamp?: boolean;
}

interface ShoppingOutletState {
    isHidden: boolean;
    isLoading: boolean;
}

export default class ShoppingOutlet extends Component<
    ShoppingOutletProps,
    ShoppingOutletState
> {
    static ALLOWED_CLICKABLE_SELECTORS = [
        `[data-${PRODUCT_KEY_ATTRIBUTE}][data-${CHANNEL_KEY_ATTRIBUTE}]`,
        `#${SHOPPING_OUTLET}`,
        "[href^='#usc-']",
        ".usc-modal",
        "axo-auth",
        "paypal-card-selector",
        "paypal-shipping-address-selector",
        "paypal-consent-modal",
        "#onetrust-consent-sdk"
    ];

    private readonly uscRootRef: RefObject<HTMLDivElement>;

    constructor(props: ShoppingOutletProps) {
        super(props);

        this.state = {
            isHidden: true,
            isLoading: true
        };

        this.uscRootRef = createRef<HTMLDivElement>();
    }

    componentDidMount() {
        window.addEventListener("click", this.onClickOutside, true);
        window.addEventListener("orientationchange", this.onOrientationChange);
    }

    componentDidUpdate() {
        const { isHidden } = this.state;

        if (!isHidden) {
            this.uscRootRef?.current?.focus();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.onClickOutside);
        window.removeEventListener(
            "orientationchange",
            this.onOrientationChange
        );
    }

    private onOrientationChange = (): void => {
        // (IPhones only): This thing enforces layout recalculation by resetting fixed position attached with the class
        // eslint-disable-next-line react/destructuring-assignment
        if (isIPhone() && !this.state.isHidden) {
            document.body.classList.remove(CLASS_NAME_FOR_SHOWN_WIDGET_ON_BODY);
            setTimeout(() => {
                document.body.classList.add(
                    CLASS_NAME_FOR_SHOWN_WIDGET_ON_BODY
                );
            }, 400);
        }
    };

    private onClickOutside = (event: MouseEvent): void => {
        const { isHidden } = this.state;

        const clickedAllowedElement =
            ShoppingOutlet.ALLOWED_CLICKABLE_SELECTORS.some(selector =>
                (event.target as HTMLElement).closest(selector)
            );

        if (!clickedAllowedElement && !isHidden) {
            this.hideUSCWidget();
        }
    };

    public hideUSCWidget() {
        const { hideShoppingButton } = this.props;

        this.setState({
            isHidden: true
        });

        clearLocationHash();
        hideShoppingButton(false);
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    async renderOutlet(onRenderOutletCallback: () => void) {
        try {
            const {
                iframeProducts,
                lang,
                onCartAmountChange,
                theme,
                uscRevamp
            } = this.props;

            if (!window.usc) {
                this.setState({
                    isLoading: true,
                    isHidden: false
                });

                if (uscRevamp) {
                    await loadWidgetV3();
                } else {
                    await loadWidget();
                }

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.usc.onWidgetClose = () => {
                    this.hideUSCWidget();
                };

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.usc.onCartAmountChange = (cartAmount: number): void => {
                    onCartAmountChange(cartAmount);
                };

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.usc.setPageProducts(
                    getShoppableProducts(iframeProducts)
                );
            } else {
                this.setState({
                    isHidden: false
                });
            }

            if (this.uscRootRef.current) {
                window.usc.unmountWidget(this.uscRootRef.current);
            }

            onRenderOutletCallback();

            if (this.uscRootRef.current) {
                window.usc.renderWidget(this.uscRootRef.current, {
                    theme,
                    lang
                });
            }

            this.setState({
                isLoading: false
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log("USC: can not initialize usc", error);
        }
    }

    render() {
        const { isHidden, isLoading } = this.state;
        const { uscRevamp } = this.props;

        updateBodyClassList(isHidden);

        return (
            <div
                className={classNames("usc-outlet", {
                    "is-hidden": isHidden,
                    "usc-outlet-v3": uscRevamp
                })}
                aria-hidden={isHidden ? "true" : "false"}
            >
                {isLoading && (
                    <div className="usc-outlet-loader">
                        {uscRevamp ? (
                            <div
                                aria-live="assertive"
                                className="usc-icon-circle-progress"
                            >
                                <svg
                                    width="60"
                                    height="66"
                                    viewBox="0 -5 60 66"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g
                                        className="progress-rotate"
                                        filter="url(#filter0_d_870_15799)"
                                    >
                                        <path
                                            d="M23.0721 46.8178C32.2333 49.6539 42.6304 45.4045 47.2086 37.037C52.0596 28.3529 50.0631 17.3961 42.4662 10.8913C29.8366 0.222607 10.6498 6.68937 6.72853 22.5793C5.48311 27.5251 6.06037 32.9204 8.30723 37.594C8.4938 37.9818 8.32796 38.4471 7.93727 38.6323C7.55615 38.8127 7.10167 38.6608 6.90553 38.2952C5.59792 35.8563 4.70492 33.1863 4.29031 30.4325C2.69885 20.6073 7.78578 10.2614 16.5627 5.36943C33.4133 -4.1313 54.0816 7.55825 54.9746 26.6737C55.5327 39.0738 47.0205 50.2775 34.874 53.2229C30.425 54.3402 25.6873 54.2326 21.2685 53.0203C19.5351 52.5518 18.513 50.7761 18.985 49.0557C19.4634 47.3021 21.3227 46.2845 23.0721 46.8162V46.8178Z"
                                            fill="#959595"
                                        />
                                    </g>
                                    <defs>
                                        <filter
                                            id="filter0_d_870_15799"
                                            x="0.000610352"
                                            y="-0.000320776"
                                            width="58.9999"
                                            height="59.9994"
                                            filterUnits="userSpaceOnUse"
                                            colorInterpolationFilters="sRGB"
                                        >
                                            <feFlood
                                                // eslint-disable-next-line react/no-unknown-property
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset dy="2" />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="out"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="BackgroundImageFix"
                                                result="effect1_dropShadow_870_15799"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="effect1_dropShadow_870_15799"
                                                result="shape"
                                            />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>
                        ) : (
                            <div aria-live="assertive" className="lds-ripple">
                                <div />
                                <div />
                            </div>
                        )}
                    </div>
                )}
                <div
                    aria-label="Shopping cart available"
                    className={classNames("usc-root", {
                        "usc-root_hidden": isHidden
                    })}
                    id={SHOPPING_OUTLET_CONTENT}
                    ref={this.uscRootRef}
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                    tabIndex={0}
                />
            </div>
        );
    }
}
