// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { h } from "preact";
import "./ShoppingCartIcon.scss";

interface ShoppingCartIconProps {
    cartItemsCount: string;
}

export function ShoppingCartIcon({ cartItemsCount }: ShoppingCartIconProps) {
    return (
        <svg aria-hidden="true" x="0px" y="0px" viewBox="0 0 50 50">
            <rect className="shopping-cart-icon-st0" width="50" height="50" />
            <g>
                <circle
                    className="shopping-cart-icon-st1"
                    cx="35.304"
                    cy="14.631"
                    r="8.519"
                />
                <text
                    x="35"
                    y="17.5"
                    className="shopping-cart-icon-st2 shopping-cart-icon-st3 shopping-cart-icon-st4"
                >
                    {cartItemsCount}
                </text>
            </g>
            <g>
                <path
                    className="shopping-cart-icon-st2"
                    d="M34.67,24.652l-0.254,0.972H16.362l-2.791-9.894h11.754c-0.042-0.381-0.042-0.719-0.042-1.099
		c0-0.254,0-0.507,0.042-0.761H13.149c-0.042,0-0.042,0-0.085,0l-1.142-4.059c-0.254-1.057-1.015-1.776-1.903-1.776H7.06
		c-0.423,0-0.846,0.381-0.888,0.93C6.13,9.6,6.51,10.149,7.018,10.149h3.002c0.127,0,0.254,0.127,0.296,0.296L14.84,26.47
		c0.169,0.634,0.634,1.057,1.184,1.057c3.805,0,18.604,0,18.604,0c0.507,0,0.972-0.423,1.142-1.015l0.507-1.86
		c-0.296,0.042-0.634,0.042-0.93,0.042C35.093,24.694,34.881,24.694,34.67,24.652z"
                />
                <g>
                    <path
                        className="shopping-cart-icon-st2"
                        d="M19.237,28.88c-1.522,0-2.748,1.226-2.748,2.748c0,1.522,1.226,2.748,2.748,2.748
			c1.522,0,2.748-1.226,2.748-2.748C21.986,30.106,20.759,28.88,19.237,28.88z M19.237,32.601c-0.55,0-0.972-0.423-0.972-0.972
			s0.423-0.972,0.972-0.972c0.55,0,0.972,0.423,0.972,0.972C20.21,32.136,19.787,32.601,19.237,32.601z"
                    />
                    <path
                        className="shopping-cart-icon-st2"
                        d="M30.992,28.88c-1.522,0-2.748,1.226-2.748,2.748c0,1.522,1.226,2.748,2.748,2.748s2.748-1.226,2.748-2.748
			C33.74,30.106,32.471,28.88,30.992,28.88z M30.992,32.601c-0.55,0-0.972-0.423-0.972-0.972s0.423-0.972,0.972-0.972
			c0.55,0,0.972,0.423,0.972,0.972C31.922,32.136,31.499,32.601,30.992,32.601z"
                    />
                </g>
            </g>
            <g>
                <path
                    className="shopping-cart-icon-st2"
                    d="M12.035,43.088l0.739-0.883c0.479,0.377,0.999,0.575,1.554,0.575c0.363,0,0.554-0.123,0.554-0.329v-0.014
		c0-0.205-0.157-0.308-0.815-0.465c-1.02-0.233-1.807-0.52-1.807-1.499v-0.014c0-0.89,0.705-1.533,1.855-1.533
		c0.815,0,1.451,0.219,1.971,0.637L15.424,40.5c-0.438-0.315-0.917-0.472-1.342-0.472c-0.322,0-0.479,0.13-0.479,0.308v0.014
		c0,0.219,0.164,0.315,0.835,0.465c1.095,0.24,1.78,0.596,1.78,1.492v0.014c0,0.979-0.773,1.561-1.937,1.561
		C13.432,43.882,12.631,43.615,12.035,43.088z"
                />
                <path
                    className="shopping-cart-icon-st2"
                    d="M18.915,39.008h1.328v1.793h1.704v-1.793h1.328V43.8h-1.328v-1.821h-1.704V43.8h-1.328V39.008z"
                />
                <path
                    className="shopping-cart-icon-st2"
                    d="M26.02,41.418v-0.014c0-1.376,1.109-2.492,2.587-2.492s2.574,1.102,2.574,2.478v0.014
		c0,1.376-1.109,2.492-2.587,2.492S26.02,42.794,26.02,41.418z M29.819,41.418v-0.014c0-0.698-0.493-1.294-1.225-1.294
		s-1.218,0.589-1.218,1.28v0.014c0,0.691,0.5,1.294,1.232,1.294C29.333,42.698,29.819,42.109,29.819,41.418z"
                />
                <path
                    className="shopping-cart-icon-st2"
                    d="M33.919,39.008h2.06c1.205,0,1.985,0.623,1.985,1.677v0.014c0,1.136-0.869,1.732-2.054,1.732h-0.664V43.8
		h-1.328V39.008z M35.884,41.39c0.465,0,0.76-0.253,0.76-0.63v-0.014c0-0.411-0.294-0.623-0.767-0.623h-0.63v1.266H35.884z"
                />
            </g>
        </svg>
    );
}
