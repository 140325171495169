export var MParticleEvents;
(function (MParticleEvents) {
    MParticleEvents["PRODUCT_CLICKED"] = "Product clicked";
    MParticleEvents["PRODUCT_LOADED"] = "Product loaded";
    MParticleEvents["PRODUCT_VIEWED"] = "Product viewed";
    MParticleEvents["SPONSOR_VIEWED"] = "Sponsor Viewed";
    MParticleEvents["SPONSOR_IMPRESSION"] = "Sponsor Impression";
    MParticleEvents["SPONSOR_CLICKED"] = "Sponsor Clicked";
    MParticleEvents["IAB_IMPRESSION"] = "IAB Impression";
    MParticleEvents["SHOPPABLE_CONTENT_LOAD"] = "Shoppable Content Load";
    MParticleEvents["SHOPPABLE_PAGE_LOAD"] = "Shoppable page load";
    MParticleEvents["BACK_IN_STOCK"] = "BackInStock";
    MParticleEvents["CART_VIEWED"] = "Cart Viewed";
    MParticleEvents["CHECKOUT_METHOD_CLICK"] = "Checkout Method Click";
    MParticleEvents["CLICK_ACTION"] = "Click Action";
    MParticleEvents["VIDEO_START"] = "Video Start";
    MParticleEvents["VIEWED_SHOP_ALL_LIST"] = "Viewed Shop All List";
    MParticleEvents[MParticleEvents["UNKNOWN"] = 0] = "UNKNOWN";
    MParticleEvents[MParticleEvents["ADD_TO_CART"] = 1] = "ADD_TO_CART";
    MParticleEvents[MParticleEvents["REMOVE_FROM_CART"] = 2] = "REMOVE_FROM_CART";
    MParticleEvents[MParticleEvents["CHECKOUT_OPTION"] = 4] = "CHECKOUT_OPTION";
    MParticleEvents[MParticleEvents["CLICK"] = 5] = "CLICK";
    MParticleEvents[MParticleEvents["VIEW_DETAIL"] = 6] = "VIEW_DETAIL";
    MParticleEvents[MParticleEvents["PURCHASE"] = 7] = "PURCHASE";
})(MParticleEvents || (MParticleEvents = {}));
export var ContentType;
(function (ContentType) {
    ContentType["EPW"] = "Embeddable Products Widget";
    ContentType["CCW"] = "Contextual Commerce Widget";
    ContentType["SPONSORSHIP_BANNER"] = "sponsorship banner";
    ContentType["SPONSORED_PRODUCTS"] = "Sponsored Products";
    ContentType["AD"] = "AD";
})(ContentType || (ContentType = {}));
export var CustomEventType;
(function (CustomEventType) {
    CustomEventType[CustomEventType["Navigation"] = 1] = "Navigation";
    CustomEventType[CustomEventType["Location"] = 2] = "Location";
    CustomEventType[CustomEventType["Search"] = 3] = "Search";
    CustomEventType[CustomEventType["Transaction"] = 4] = "Transaction";
    CustomEventType[CustomEventType["UserContent"] = 5] = "UserContent";
    CustomEventType[CustomEventType["UserPreference"] = 6] = "UserPreference";
    CustomEventType[CustomEventType["Social"] = 7] = "Social";
    CustomEventType[CustomEventType["Other"] = 8] = "Other";
})(CustomEventType || (CustomEventType = {}));
export const ANALYTICS_MESSAGES = {
    analyticsLogCustomEvent: {
        info: "USC: [mParticle] analyticsLogCustomEvent not sent.",
        error: "USC: [mParticle] analyticsLogCustomEvent error sending event."
    },
    analyticsLogImpression: {
        info: "USC: [mParticle] analyticsLogImpression not sent.",
        error: "USC: [mParticle] analyticsLogImpression error sending event."
    },
    analyticsLogProductAction: {
        info: "USC: [mParticle] analyticsLogProductAction not sent.",
        error: "USC: [mParticle] analyticsLogProductAction error sending event."
    },
    analyticsLogLineItemAction: {
        info: "USC: [mParticle] analyticsLogLineItemAction not sent.",
        error: "USC: [mParticle] analyticsLogLineItemAction error sending event."
    },
    analyticsLogCheckoutOption: {
        info: "USC: [mParticle] analyticsLogCheckoutOption not sent.",
        error: "USC: [mParticle] analyticsLogCheckoutOption error sending event."
    },
    analyticsLogPurchase: {
        info: "USC: [mParticle] analyticsLogPurchase not sent.",
        error: "USC: [mParticle] analyticsLogPurchase error sending event."
    },
    analyticsModifyIdentity: {
        info: "USC: [mParticle] analyticsModifyIdentity not sent.",
        error: "USC: [mParticle] analyticsModifyIdentity error sending event."
    }
};
